import React from 'react'

const Header = () => {
    return (
        <div className="header">
            <h2>NCentia - BeniBit Application</h2>
            <h4>W. Schneider</h4>
        </div>

    )
}

export default Header;
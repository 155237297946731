/* src/App.js */
import React, { useEffect, useState } from 'react'
import Header from "./Components/Header";
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { createTodo, deleteTodo } from './graphql/mutations'
import { listTodos } from './graphql/queries'
import { withAuthenticator } from '@aws-amplify/ui-react';
import "./App.css";
import awsExports from "./aws-exports";
import { v4 as uuidV4 } from "uuid";
Amplify.configure(awsExports);


const initialState = { name: '', description: '' }

const App = () => {
  const [formState, setFormState] = useState(initialState)
  const [todos, setTodos] = useState([])

  useEffect(() => {
    fetchTodos()
  }, [])

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }

  function stubEdit(id ) {
      setTodos(todos.filter((todo) => todo.id !== id));
  }

  function stubComplete(id ) {
      setTodos(todos.filter((todo) => todo.id !== id));
  }

  async function fetchTodos() {
    try {
      const todoData = await API.graphql(graphqlOperation(listTodos))
      const todos = todoData.data.listTodos.items
      setTodos(todos)
    } catch (err) { console.log('error fetching todos') }
  }

  async function addTodo() {
    try {
      if (!formState.name || !formState.description) return
      const todo = {id:uuidV4(), name:formState.name, description:formState.description}
      setTodos([...todos, todo])
      setFormState(initialState)
      await API.graphql(graphqlOperation(createTodo, {input: todo}))
    } catch (err) {
      console.log('error creating todo:', err)
    }
  };  
  
  async function removeTodo(todo) {
    try {
      const goneTodo = todo
      const id = todo.id
      setTodos(todos.filter((todo) => todo.id !== goneTodo.id))
      await API.graphql(graphqlOperation(deleteTodo, {input: {id}}))
    } catch (err) {
      console.log('error deleting todo:', err)
    }
  };  

  return (
    <div className="container">
      <div className="app-wrapper">
        <div>
          <Header/>
        </div>
          <input className="task-input"
            onChange={event => setInput('name', event.target.value)}
            value={formState.name}
            placeholder="Name"
        />
        <input className="task-input"
            onChange={event => setInput('description', event.target.value)}
            value={formState.description}
            placeholder="Description"
        />

      <button className="button-add" onClick={addTodo}>ADD</button>

      {todos.map((todo) => (
          <div>            
            <li className="list-item" key={todo.id}>
              <input
                type="text"
                value={todo.name + " " + todo.description}
                className={`list ${todo.completed ? "complete" : ""}`}
                onChange={(event) => event.preventDefault()}
              />                   
                <button className="button-complete task-button" onClick= {() => stubComplete(todo)}>
                    <i className="fa fa-check-circle"></i>
                </button>
                <button className="button-edit task-button" onClick= {() => stubEdit(todo)}>
                    <i className="fa fa-edit"></i>
                </button>
                <button className="button-delete task-button" onClick={() => removeTodo(todo)}>
                    <i className="fa fa-trash"></i>
                </button>
                </li>
          </div>
        ))
      }
    </div>
  </div>
  )
}



export default withAuthenticator(App)
